<template>
  <div v-if="requisits" class="requisits">
    <p class="title">
      {{ department ? "Реквизиты филиала :" : "Реквизиты поставщика :" }}
    </p>
    <p><span class="font-weight-black">Адрес: </span>{{ requisits.address }}</p>
    <p>
      <span class="font-weight-black">Почтовый адрес: </span
      >{{ requisits.postAddress }}
    </p>
    <p><span class="font-weight-black">АО: </span>{{ requisits.ao }}</p>
    <p><span class="font-weight-black">БИК: </span>{{ requisits.bik }}</p>
    <p><span class="font-weight-black">БИН: </span>{{ requisits.bin }}</p>
    <p><span class="font-weight-black">ИИК: </span>{{ requisits.iik }}</p>
    <p><span class="font-weight-black">КБЕ: </span>{{ requisits.kbe }}</p>
    <p><span class="font-weight-black">КНП: </span>{{ requisits.knp }}</p>
    <p><span class="font-weight-black">Телефон: </span>{{ requisits.phone }}</p>
    <p>
      <span class="font-weight-black">Имя директора: </span
      >{{ requisits.directorName }}
    </p>
    <p v-if="requisits.commissionPercent">
      <span class="font-weight-black">Комиссия: </span
      >{{ requisits.commissionPercent }}%
    </p>
  </div>
</template>

<script>
export default {
  name: "RequisitsBlock",
  props: {
    requisits: {
      type: Object,
      default: () => {},
    },
    department: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.requisits {
  background-color: #51c8f0;
  color: white;
  border-radius: 20px;
  padding: 20px 40px;
  width: max-content;
  p {
    margin-bottom: 0;
  }
}
.title {
  display: block;
  color: white;
  margin-bottom: 5px;
}
</style>
